<template>
    <svg v-if="direction == 'up' || direction == 'down'" v-bind="$attrs" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 18" class="icon-svg icon-arrow-long" :class="[color ? color : '', size ? 'icon--size-' + size : '', direction ? 'icon--direction-' + direction : '']">
        <path d="M11.2,5.2l-5-5.1l0,0C6-0.1,5.7,0,5.5,0.2l-4.9,5C0.5,5.3,0.4,5.5,0.4,5.6S0.5,5.9,0.6,6c0.2,0.2,0.6,0.2,0.8,0l3.9-4v15.4c0,0.3,0.3,0.6,0.6,0.6l0,0c0.3,0,0.6-0.3,0.6-0.6V2l3.9,4c0.2,0.2,0.6,0.2,0.8,0c0.1-0.1,0.2-0.2,0.2-0.4S11.3,5.3,11.2,5.2L11.2,5.2L11.2,5.2z" />
    </svg>
    <svg v-else v-bind="$attrs" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 12" class="icon-svg icon-arrow-long" :class="[color ? color : '', size ? 'icon--size-' + size : '', direction ? 'icon--direction-' + direction : '']">
        <path d="M5.2,0.6l-5.1,5h0C-0.1,5.8,0,6.1,0.2,6.3l5,4.9c0.1,0.1,0.3,0.2,0.4,0.2s0.3-0.1,0.4-0.2c0.2-0.2,0.2-0.6,0-0.8L2,6.5h15.4c0.3,0,0.6-0.3,0.6-0.6v0c0-0.3-0.3-0.6-0.6-0.6H2l4-3.9c0.2-0.2,0.2-0.6,0-0.8C5.9,0.5,5.8,0.4,5.6,0.4S5.3,0.5,5.2,0.6L5.2,0.6L5.2,0.6z" />
    </svg>
</template>

<script>
export default {
    props: {
        color: { type: String, default: "" },
        size: { type: String, default: "default" },
        direction: { type: String, default: "left" },
    },
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// Direction
.icon--direction {
    &-right,
    &-down {
        transform: rotate(180deg);
    }
    &-left,
    &-right {
        // Size
        &.icon--size {
            &-xx-small {
                width: 12px;
            }
            &-x-small {
                width: 14px;
            }
            &-small {
                width: 16px;
            }
            &-default {
                width: 18px;
            }
            &-large {
                width: 20px;
            }
            &-x-large {
                width: 22px;
            }
            &-xx-large {
                width: 24px;
            }
        }
    }
    &-up,
    &-down {
        // Size
        &.icon--size {
            &-xx-small {
                height: 12px;
            }
            &-x-small {
                height: 14px;
            }
            &-small {
                height: 16px;
            }
            &-default {
                height: 18px;
            }
            &-large {
                height: 20px;
            }
            &-x-large {
                height: 22px;
            }
            &-xx-large {
                height: 24px;
            }
        }
    }
}
</style>
