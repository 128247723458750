var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.direction == 'up' || _vm.direction == 'down' ? _c('svg', _vm._b({
    staticClass: "icon-svg icon-arrow-long",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : '', _vm.direction ? 'icon--direction-' + _vm.direction : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 12 18"
    }
  }, 'svg', _vm.$attrs, false), [_c('path', {
    attrs: {
      "d": "M5.9,0L0.4,5.9l0.9,1l3.9-4.2V18h1.4V2.7l3.9,4.2l0.9-1L5.9,0z"
    }
  })]) : _c('svg', _vm._b({
    staticClass: "icon-svg icon-arrow-long",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : '', _vm.direction ? 'icon--direction-' + _vm.direction : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 18 12"
    }
  }, 'svg', _vm.$attrs, false), [_c('path', {
    attrs: {
      "d": "M0,5.9l5.9,5.5l1-0.9L2.7,6.6H18V5.2H2.7l4.2-3.9l-1-0.9L0,5.9z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }