import { render, staticRenderFns } from "./forms-text-field.vue?vue&type=template&id=50834121&scoped=true"
import script from "./forms-text-field.vue?vue&type=script&lang=js"
export * from "./forms-text-field.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50834121",
  null
  
)

export default component.exports