var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.direction == 'up' || _vm.direction == 'down' ? _c('svg', _vm._b({
    staticClass: "icon-svg icon-arrow-long",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : '', _vm.direction ? 'icon--direction-' + _vm.direction : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 12 18"
    }
  }, 'svg', _vm.$attrs, false), [_c('path', {
    attrs: {
      "d": "M11.2,5.2l-5-5.1l0,0C6-0.1,5.7,0,5.5,0.2l-4.9,5C0.5,5.3,0.4,5.5,0.4,5.6S0.5,5.9,0.6,6c0.2,0.2,0.6,0.2,0.8,0l3.9-4v15.4c0,0.3,0.3,0.6,0.6,0.6l0,0c0.3,0,0.6-0.3,0.6-0.6V2l3.9,4c0.2,0.2,0.6,0.2,0.8,0c0.1-0.1,0.2-0.2,0.2-0.4S11.3,5.3,11.2,5.2L11.2,5.2L11.2,5.2z"
    }
  })]) : _c('svg', _vm._b({
    staticClass: "icon-svg icon-arrow-long",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : '', _vm.direction ? 'icon--direction-' + _vm.direction : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 18 12"
    }
  }, 'svg', _vm.$attrs, false), [_c('path', {
    attrs: {
      "d": "M5.2,0.6l-5.1,5h0C-0.1,5.8,0,6.1,0.2,6.3l5,4.9c0.1,0.1,0.3,0.2,0.4,0.2s0.3-0.1,0.4-0.2c0.2-0.2,0.2-0.6,0-0.8L2,6.5h15.4c0.3,0,0.6-0.3,0.6-0.6v0c0-0.3-0.3-0.6-0.6-0.6H2l4-3.9c0.2-0.2,0.2-0.6,0-0.8C5.9,0.5,5.8,0.4,5.6,0.4S5.3,0.5,5.2,0.6L5.2,0.6L5.2,0.6z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }