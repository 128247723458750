import { render, staticRenderFns } from "./u-select-solo-tile.vue?vue&type=template&id=1a72ab4e&scoped=true"
import script from "./u-select-solo-tile.vue?vue&type=script&lang=js"
export * from "./u-select-solo-tile.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a72ab4e",
  null
  
)

export default component.exports