import { render, staticRenderFns } from "./footers-default.vue?vue&type=template&id=38a139da&scoped=true"
import script from "./footers-default.vue?vue&type=script&lang=js"
export * from "./footers-default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38a139da",
  null
  
)

export default component.exports