<template>
    <u-btn-flat-rounded v-bind="$attrs" color="secondary" @click="$emit('click')">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </u-btn-flat-rounded>
</template>

<script>
import UBtnFlatRounded from "@/components/publish/styles/buttons/u-btn-flat-rounded.vue";

export default {
    props: {},
    components: {
        UBtnFlatRounded,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
