var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.direction == 'up' || _vm.direction == 'down' ? _c('svg', _vm._b({
    staticClass: "icon-svg icon-chevron-rounded",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : '', _vm.direction ? 'icon--direction-' + _vm.direction : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 10"
    }
  }, 'svg', _vm.$attrs, false), [_c('path', {
    attrs: {
      "d": "M0.4,9c0.2,0.3,0.7,0.3,0.9,0L8,2.3L14.7,9c0.2,0.3,0.7,0.3,0.9,0l0,0c0.2-0.2,0.2-0.6,0-0.9L8,0.5L0.4,8.2C0.2,8.4,0.2,8.8,0.4,9L0.4,9z"
    }
  })]) : _c('svg', _vm._b({
    staticClass: "icon-svg icon-chevron-rounded",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : '', _vm.direction ? 'icon--direction-' + _vm.direction : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 10 16"
    }
  }, 'svg', _vm.$attrs, false), [_c('path', {
    attrs: {
      "d": "M9,15.6c0.3-0.2,0.3-0.7,0-0.9L2.3,8L9,1.3c0.3-0.2,0.3-0.7,0-0.9l0,0c-0.2-0.2-0.6-0.2-0.9,0L0.5,8l7.7,7.6C8.4,15.8,8.8,15.8,9,15.6L9,15.6z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }