<template>
    <svg v-if="direction == 'up' || direction == 'down'" v-bind="$attrs" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 18" class="icon-svg icon-arrow-long" :class="[color ? color : '', size ? 'icon--size-' + size : '', direction ? 'icon--direction-' + direction : '']">
        <path d="M5.9,0L0.4,5.9l0.9,1l3.9-4.2V18h1.4V2.7l3.9,4.2l0.9-1L5.9,0z" />
    </svg>
    <svg v-else v-bind="$attrs" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 12" class="icon-svg icon-arrow-long" :class="[color ? color : '', size ? 'icon--size-' + size : '', direction ? 'icon--direction-' + direction : '']">
        <path d="M0,5.9l5.9,5.5l1-0.9L2.7,6.6H18V5.2H2.7l4.2-3.9l-1-0.9L0,5.9z" />
    </svg>
</template>

<script>
export default {
    props: {
        color: { type: String, default: "" },
        size: { type: String, default: "default" },
        direction: { type: String, default: "left" },
    },
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// Direction
.icon--direction {
    &-right,
    &-down {
        transform: rotate(180deg);
    }
    &-left,
    &-right {
        // Size
        &.icon--size {
            &-xx-small {
                width: 12px;
            }
            &-x-small {
                width: 14px;
            }
            &-small {
                width: 16px;
            }
            &-default {
                width: 18px;
            }
            &-large {
                width: 20px;
            }
            &-x-large {
                width: 22px;
            }
            &-xx-large {
                width: 24px;
            }
        }
    }
    &-up,
    &-down {
        // Size
        &.icon--size {
            &-xx-small {
                height: 12px;
            }
            &-x-small {
                height: 14px;
            }
            &-small {
                height: 16px;
            }
            &-default {
                height: 18px;
            }
            &-large {
                height: 20px;
            }
            &-x-large {
                height: 22px;
            }
            &-xx-large {
                height: 24px;
            }
        }
    }
}
</style>
