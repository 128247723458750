<template>
    <u-tit-wrap-line :class="[titWrapClass, titWrapSize, titWrapAlign]">
        <u-tit-subtit v-if="this.$slots['subtit']" class="tit--xs" data-aos="fade-up" data-aos-delay="100"><slot name="subtit" /></u-tit-subtit>
        <u-tit-default v-if="this.$slots['tit']" :class="[titClass, titSize]" data-aos="fade-up" data-aos-delay="200"><slot /></u-tit-default>
        <u-tit-default v-else :class="[titClass, titSize]" data-aos="fade-up" data-aos-delay="200"><slot /></u-tit-default>
        <u-txt-default v-if="this.$slots['txt']" :class="[txtClass, txtSize]" data-aos="fade-up" data-aos-delay="300"><slot name="txt" /></u-txt-default>
    </u-tit-wrap-line>
</template>

<script>
import UTitWrapLine from "@/components/publish/styles/typography/u-tit-wrap-line.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitSubtit from "@/components/publish/styles/typography/u-tit-subtit.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {
        titWrapClass: { type: String, default: "" },
        titWrapSize: { type: String, default: "tit-wrap--xl" },
        titWrapAlign: { type: String, default: "text-center" },
        titClass: { type: String, default: "" },
        titSize: { type: String, default: "tit--lg" },
        txtClass: { type: String, default: "" },
        txtSize: { type: String, default: "txt--lg" },
    },
    components: {
        UTitWrapLine,
        UTitDefault,
        UTitSubtit,
        UTxtDefault,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
