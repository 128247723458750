<template>
    <svg v-if="direction == 'up' || direction == 'down'" v-bind="$attrs" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 10" class="icon-svg icon-chevron-rounded" :class="[color ? color : '', size ? 'icon--size-' + size : '', direction ? 'icon--direction-' + direction : '']">
        <path d="M0.4,9c0.2,0.3,0.7,0.3,0.9,0L8,2.3L14.7,9c0.2,0.3,0.7,0.3,0.9,0l0,0c0.2-0.2,0.2-0.6,0-0.9L8,0.5L0.4,8.2C0.2,8.4,0.2,8.8,0.4,9L0.4,9z" />
    </svg>
    <svg v-else v-bind="$attrs" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16" class="icon-svg icon-chevron-rounded" :class="[color ? color : '', size ? 'icon--size-' + size : '', direction ? 'icon--direction-' + direction : '']">
        <path d="M9,15.6c0.3-0.2,0.3-0.7,0-0.9L2.3,8L9,1.3c0.3-0.2,0.3-0.7,0-0.9l0,0c-0.2-0.2-0.6-0.2-0.9,0L0.5,8l7.7,7.6C8.4,15.8,8.8,15.8,9,15.6L9,15.6z" />
    </svg>
</template>

<script>
export default {
    props: {
        color: { type: String, default: "" },
        size: { type: String, default: "default" },
        direction: { type: String, default: "left" },
    },
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// Direction
.icon--direction {
    &-right,
    &-down {
        transform: rotate(180deg);
    }
    &-left,
    &-right {
        // Size
        &.icon--size {
            &-xx-small {
                width: 5px;
            }
            &-x-small {
                width: 6px;
            }
            &-small {
                width: 8px;
            }
            &-default {
                width: 10px;
            }
            &-large {
                width: 12px;
            }
            &-x-large {
                width: 14px;
            }
            &-xx-large {
                width: 16px;
            }
        }
    }
    &-up,
    &-down {
        // Size
        &.icon--size {
            &-xx-small {
                height: 5px;
            }
            &-x-small {
                height: 6px;
            }
            &-small {
                height: 8px;
            }
            &-default {
                height: 10px;
            }
            &-large {
                height: 12px;
            }
            &-x-large {
                height: 14px;
            }
            &-xx-large {
                height: 16px;
            }
        }
    }
}
</style>
