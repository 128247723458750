import { render, staticRenderFns } from "./swiper-pagination-secondary.vue?vue&type=template&id=4cb7511a&scoped=true"
import script from "./swiper-pagination-secondary.vue?vue&type=script&lang=js"
export * from "./swiper-pagination-secondary.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cb7511a",
  null
  
)

export default component.exports