import { render, staticRenderFns } from "./headers-language-items.vue?vue&type=template&id=2b1f4efe&scoped=true"
import script from "./headers-language-items.vue?vue&type=script&lang=js"
export * from "./headers-language-items.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b1f4efe",
  null
  
)

export default component.exports