import { render, staticRenderFns } from "./u-card-border-bottom.vue?vue&type=template&id=53b5e9c4&scoped=true"
import script from "./u-card-border-bottom.vue?vue&type=script&lang=js"
export * from "./u-card-border-bottom.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53b5e9c4",
  null
  
)

export default component.exports